import React from "react"

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Typography from "../components/typography"
import Button from "../components/button"

import "./home.css"
import SelectableList from "../components/selectableList"
import posed, { PoseGroup } from "react-pose"

const Pose = posed.div({
  enter: {
    opacity: 1,
    delay: 150,
    transition: {
      default: { duration: 300 },
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 250 },
  },
})

const PoseText = posed.div({
  enter: {
    opacity: 1,
    delay: 150,
    transition: {
      default: { duration: 300 },
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 250 },
  },
})

const PoseSpan = posed.span({
  enter: {
    opacity: 1,
    delay: 150,
    transition: {
      default: { duration: 300 },
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 250 },
  },
})

const HeroSection = ({ loaded, images, setLoaded }) => {
  const content = React.useRef([
    {
      text: "lattes",
      image: images[0].node.childImageSharp.fluid,
    },
    {
      text: "travel",
      image: images[1].node.childImageSharp.fluid,
    },
    {
      text: "brunch",
      image: images[2].node.childImageSharp.fluid,
    },
    {
      text: "yoga classes",
      image: images[3].node.childImageSharp.fluid,
    },
    {
      text: "tech",
      image: images[4].node.childImageSharp.fluid,
    },
    {
      text: "long weekends",
      image: images[5].node.childImageSharp.fluid,
    },
    {
      text: "freedom",
      image: images[6].node.childImageSharp.fluid,
    },
  ])

  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      const i = index

      if (i === content.current.length - 1) {
        setIndex(0)
      } else {
        setIndex(i + 1)
      }
    }, 4000)

    return () => clearInterval(timer)
  }, [index])

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])
  return (
    <div className="home__container page-wrapper">
      <div className="home__content">
        <Typography variant="h1">
          Less student loans, more{" "}
          <span>
            {loaded &&
              content.current.map((node, idx) => (
                <PoseText
                  key={idx}
                  className="pose-span"
                  pose={idx === index ? "enter" : "exit"}
                >
                  {node.text}
                </PoseText>
              ))}
          </span>
          <PoseGroup>
            {!loaded && (
              <PoseSpan
                key="text"
                className="pose-span"
                style={{ textDecoration: "none", fontSize: 48 }}
                pose={loaded ? "exit" : "enter"}
              >
                ✨ ✈️ ☕️
              </PoseSpan>
            )}
          </PoseGroup>
        </Typography>
        <Typography
          variant="body1"
          style={{ opacity: 0.6, marginTop: 12, marginBottom: 48 }}
        >
          Helping borrowers across the country protect their quality of life
          while paying off their student loans - the right way.
        </Typography>
        <Button variant="primary" to="/how-it-works">
          Learn More
        </Button>
      </div>

      <div className="home__img-container">
        {content.current.map((node, idx) => (
          <Pose
            className="home_img-poser"
            style={{ display: loaded ? "block" : "none" }}
            pose={idx === index ? "enter" : "exit"}
            key={idx}
          >
            <Img
              onLoad={() => {
                console.log("IMAGE LOADED")
                // setLoaded(true)
              }}
              loading="eager"
              fluid={node.image}
              alt=""
              className="home__hero-img"
            />
          </Pose>
        ))}
        <PoseGroup>
          {!loaded && (
            <Pose
              key="poser"
              className="home_img-poser"
              // pose={!loaded ? "enter" : "exit"}
            >
              <svg
                width="869"
                className="home_img-poser-container"
                height="613"
                viewBox="0 0 869 613"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M866.171 258.809C884.557 407.105 772.044 500.517 693.298 521.933C614.552 543.35 528.76 519.459 455.634 539.347C382.509 559.235 295.127 638.484 183.225 603.004C93.3924 574.522 5.39476 480.248 0.241659 368.132C-4.91144 256.017 72.6697 126.675 202.188 126.675C272.084 126.675 377.995 173.171 455.634 98.3767C533.273 23.582 572.305 -17.3509 672.268 7.05984C750.217 26.0948 847.785 110.513 866.171 258.809Z"
                  fill="#E9E9E9"
                />
              </svg>

              <svg
                className="home_img-poser-icon"
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="64px"
                height="64px"
                viewBox="0 0 128 128"
              >
                <rect x="0" y="0" width="100%" height="100%" fill="#E9E9E9" />
                <g>
                  <path
                    d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z"
                    fill="#26344f"
                    fill-opacity="1"
                  />
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 64 64"
                    to="360 64 64"
                    dur="1800ms"
                    repeatCount="indefinite"
                  ></animateTransform>
                </g>
              </svg>
            </Pose>
          )}
        </PoseGroup>
      </div>
    </div>
  )
}

const IndexPage = ({ data: { meta, allFile } }) => {
  const [loaded, setLoaded] = React.useState(false)
  return (
    <Layout>
      <SEO
        slug={meta.slug}
        title={meta.yoast_meta.yoast_wpseo_title}
        description={meta.yoast_meta.yoast_wpseo_metadesc}
      />
      <section className="home__hero ">
        <HeroSection
          loaded={loaded}
          setLoaded={setLoaded}
          images={allFile.edges}
        />
        <img
          src={require("../images/hero-bg.svg")}
          alt=""
          className="home__hero-bg"
        />
      </section>
      <section className="home__callout">
        <Typography variant="h2">
          Student loans are complicated and the repayment options are endless.
        </Typography>
        <Typography
          variant="body1"
          style={{ opacity: 0.6, marginTop: 12, marginBottom: 32 }}
        >
          We guide you to through the process and build a plan custom-tailored
          to your life, your goals, and your needs.
        </Typography>
      </section>
      <section className="home__two-col two-col img-left page-wrapper">
        <div className="home__img-container">
          <img
            src={require("../images/home-01.png")}
            alt=""
            className="home__img-one"
          />
          <img
            src={require("../images/home-01-bg.svg")}
            alt="Grey bg"
            className="home__img-bg img-bg--one"
          />
        </div>
        <div className="home__content-container">
          <Typography variant="h2">
            You may own the loans, but we won't let the loans own you.
          </Typography>
          <Typography
            variant="body1"
            style={{ opacity: 0.6, marginTop: 12, marginBottom: 32 }}
          >
            Student loans shouldn't take over your life, we create personalized
            plans that make your loans a thing of the past while living in the
            present and planning for the future.
          </Typography>
          <Button variant="primary" to="/how-it-works">
            Learn More
          </Button>
        </div>
      </section>

      <SelectableList text className="home__two-col home__two-col--two" />

      <section className="home__two-col home__two-col--three two-col img-left page-wrapper">
        <div className="home__content-container">
          <Typography variant="h2">
            Break free from becoming a statistic
          </Typography>
          <Typography
            variant="body1"
            style={{ opacity: 0.6, marginTop: 12, marginBottom: 32 }}
          >
            Student debt has topped $1.5 trillion in the US--but your story
            doesn't end with that number. Let's plan your future.
          </Typography>
          <Button variant="primary" to="/how-it-works">
            Learn More
          </Button>
        </div>
        <div className="home__img-container">
          <img
            src={require("../images/home-02.png")}
            alt=""
            className="home__img-one"
          />
          <img
            src={require("../images/home-02-bg.svg")}
            alt="Grey bg"
            className="home__img-bg img-bg--two"
          />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    meta: wordpressPage(slug: { eq: "home" }) {
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }

    allFile(
      sort: { fields: name }
      filter: { name: { regex: "/hero-home/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
