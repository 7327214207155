import React from "react"
import classNames from "classnames"

import "./selectable.css"
import Typography from "./typography"

const items = [
  {
    text: "Decrease the cost of your loans",
    index: 0,
    key: 1,
    alt: "Chart",
    darkIcon: require("../images/icons/chart-dark.svg"),
    lightIcon: require("../images/icons/chart-white.svg"),
    illustraion: require("../images/graph-illustration.png"),
  },
  {
    text: "Define and achieve your goals",
    index: 1,
    key: 2,
    alt: "Doc",
    darkIcon: require("../images/icons/doc-dark.svg"),
    lightIcon: require("../images/icons/doc-white.svg"),
    illustraion: require("../images/doc-illustration.png"),
  },
  {
    text: "Improve your financial quality of life",
    index: 2,
    key: 3,
    alt: "Pie",
    darkIcon: require("../images/icons/pie-dark.svg"),
    lightIcon: require("../images/icons/pie-white.svg"),
    illustraion: require("../images/nums-illustration.png"),
  },
]

const ListItem = ({
  onClick,
  darkIcon,
  lightIcon,
  alt,
  text,
  current,
  index,
}) => {
  const isCurrent = current === index

  return (
    <div
      className={classNames("selectable__list-item", {
        "selectable__list-item--active": isCurrent,
      })}
      onClick={onClick}
    >
      <img
        src={isCurrent ? lightIcon : darkIcon}
        alt={alt}
        className="selectable__item-icon"
      />
      <Typography variant="body2">{text}</Typography>
    </div>
  )
}

const SelectableList = ({ text = false, className, ...rest }) => {
  const [selected, setSelected] = React.useState(0)

  const onClick = index => () => setSelected(index)
  return (
    <section
      {...rest}
      style={{ alignItems: text ? "flex-end" : "center" }}
      className={`two-col page-wrapper selectable ${className}`}
    >
      <div className="selectable__content-container">
        {text && (
          <>
            <Typography variant="h2">
              Plans for where you are, and where you want to be
            </Typography>
            <Typography
              variant="body1"
              style={{ opacity: 0.6, marginTop: 12, marginBottom: 32 }}
            >
              Everyone starts somewhere and we will work with you no matter what
              stage you are in. Our plans are designed around your financial
              goals and focus on improving your quality of life while increasing
              your net worth.
            </Typography>
          </>
        )}
        <div style={{ marginTop: text ? 64 : 0 }} className="selectable__list">
          {items.map(item => (
            <ListItem
              {...item}
              onClick={onClick(item.index)}
              current={selected}
            />
          ))}
        </div>
      </div>
      <div className="selectable__img-container">
        <img src={items[selected].illustraion} alt="Document" />
      </div>
    </section>
  )
}
export default SelectableList
